.layout {
  margin: 0;
  padding: 0;

  .layout-wrapper {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 4.4rem;
    padding: 0 0rem 0rem 1rem;
  }
  .layout-children {
    padding: 0.5rem 0rem 0rem 2rem;
  }

  .logoCollapsed {
    overflow: hidden;
    width: 260px;
  }
  .logoStyle {
    margin-top: 0.7rem;
  }
  .logoImg {
    height: 3rem;
    width: auto;
    cursor: pointer;
  }
  .dashImg {
    width: 70%;
    justify-content: center;
  }

  .ant-Menu > .ant-menu-item-selected  {
    background-color: #90b3f9 !important;
  }
}

.ant-layout-header {
  padding: 0 0rem;
  z-index: 1000;
}

@media (max-width: 767px) {
  .sidebar {
    width:14rem !important;
    position: fixed;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    height: 100vh;
    box-shadow: 1px 1px 5px lightgrey;
  // transform: translateX(-250px);
  }
}

@media screen and (min-width:600px)  and (max-width: 850px){
  .layout {
    height: 70rem;
    
  }
 }
