@import "../../../variable.scss";

.dashboard {
  margin: 0;
  padding: 0;
  overflow: hidden;

  .vh-37 {
    height: 34.3rem;
  }

  .center-div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .spinnerTop {
    margin-top: 11rem !important;
  }
  .fileUpload {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .logoCollapsed {
    overflow: hidden;
    margin-left: -4rem;
    width: 260px;
  }
  .logoStyle {
    margin-top: 0.7rem;
  }
  .logoImg {
    height: 3rem;
    width: auto;
    cursor: pointer;
  }
  .dashImg {
    width: 50%;
    justify-content: center;
    text-align: center;
  }

  .card-image {
    text-align: center;
  }
  .ant-card-body {
    display: flex !important;
    flex-direction: column !important;
    // gap: 61px !important;
  }
  .user {
    margin-right: 35px !important;
    .ant-btn {
      background-color:#11172B;
    }
  }

  .dashCard {
    width: auto;
    height: 100%;

    overflow: hidden !important;
  }
  .bodyLayout {
    .ant-layout-content {
      margin-left: 260px !important;
      margin-top: 100px !important;
    }
  }
  .ant-layout-sider {
    flex: 0 0 4rem !important;
    width: 30rem !important;
    min-width: 3rem !important;
    max-width: 3rem !important;
  }
  .ant-card-cover{
    .coverStyle {
      overflow: hidden;
      height: 93% !important; 
      width: 93% !important;
      text-align: center;
    }
  }
  

  .ant-Menu > .ant-menu-item-selected  {
    background-color: #90b3f9 !important;
  }

  .dashSidebar {
    overflow: auto;
    height: 100vh;
    position: fixed;
    z-index: 1;
    top: 70.2px;
    max-width: 80px !important;
    min-width: 80px !important;
    background-color: #f0f2f5 !important;

    .ant-menu-inline-collapsed-noicon {
      color: black !important;
      font-size: 14px !important;
    }

    .menuSidebar {
      display: flex;
      flex-direction: column;
      .textIcon {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        margin-top: 15px !important;
      }
      .iconStyle {
        color:#11172B;
        font-size: 26px;
        margin-left: 10px;
        margin-top: 20px;
      }
      .sideMenu {
        .dashSidebar {
          width: 200px !important;
          min-width: 200px !important;
          max-width: 200px !important;
        }
      }
      .booksListMenu {
        .ant-menu {
          background-color: #f0f2f5 !important;
        }

        .ant-menu-submenu-title {
          background-color: #f0f2f5;
          display: flex;
          flex-direction: row;
          text-align: right;
          align-items: center;
          justify-content: space-around;
          .ant-menu-item-icon {
            min-width: 26px !important;
          }
        }
      }
    }

    .ant-layout-sider-trigger {
      top: 70px;
      background-color:#11172B;
      // width: 100px !important;
    }
    .ant-menu-item {
      display: flex;
      flex-direction: column;
      height: 61px;
      align-items: baseline !important;
      padding-left: 24px !important;
    }

    .ant-menu-inline-collapsed {
      margin-top: 10px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      width: 100px !important;
    }

    // .ant-menu-submenu-title {
    //   align-items: baseline !important;
    //   // padding-left: px !important;
    // }
    // .ant-menu-submenu {
    //   .ant-menu {
    //     .ant-menu-submenu-title {
    //       padding-left: 52px !important;
    //     }
    //   }
    //   .ant-menu-submenu {
    //     .ant-menu {
    //       .ant-menu-submenu-title {
    //         padding-left: 65px !important;
    //       }
    //     }
    //   }
    // }
    .ant-menu-submenu-title {
      display: flex;
      flex-direction: column;
      // height: 61px;
      .ant-menu-submenu-arrow,
      .ant-menu-submenu-arrow::before {
        background-color: grey !important;
      }
      // .ant-menu-submenu-arrow::after{
      //   background-color:#11172B;
      // }
    }
    .ant-menu-sub {
      background-color: #f0f2f5 !important;
    }

    .ant-switch {
      margin-left: 10px;
      margin-right: auto;
    }

    .ant-menu-title-content {
      margin: 0;
      margin-right: auto;
    }
    .ant-menu-submenu-arrow {
      right: 10px !important;
      color: grey;
    }
    .ant-layout-sider-children {
      margin-top: 10px;
    }
    .ant-menu.ant-menu-dark {
      background-color: #f0f2f5 !important;
    }
    .ant-menu-title-content {
      color: black;
    }
    .ant-menu-dark:not(.ant-menu-horizontal) > .ant-menu-item-selected {
      background-color:#11172B;
      .ant-menu-title-content {
        color: white;
      }
    }
    .subMenu {
      .ant-menu-title-content {
        color: white;
      }
    }
  }
  .dashSidebar:hover {
  }
  .dashHeader {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 70px;
  }

  .itemCard {
    .ant-card-bordered,
    .ant-card-cover {
      margin-top: 0px;
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
    .spinlogin::before {
      font-size: medium;
    }
  }
}

.dashboard-height {
  height: 34.3rem;
}

.dashImg:hover{
  opacity: 0.5;
}
.ant-card-cover{
  height: 70% !important;
  width: 72% !important;
}

@media (max-width: 767px) {
  .layout .layout-children {
    padding:0px !important
  }

  .brdnone {
    display: none;
  }
  .nav-svg {
    color: gray;
    margin-left: 5px;
    font-size: 20px;
}
.icon-right {
  margin-right:24px;
}
}