.botIcon {
  position: fixed;
  right: 0.5rem;
  bottom: 0.3rem;
  z-index: 9000;
}

.shake-icons {
  animation: 1s ease-in-out 0ms 1 shake;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.botIconContainer {
  display: flex;
  justify-content: end;
}

.rotate-icons {
  -webkit-animation: spin 1s linear 1;
  -moz-animation: spin 1s linear 1;
  animation: spin 1s linear 1;
}
@-moz-keyframes spin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*  */
.iconInner {
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background: #fff;
  /* background: -webkit-linear-gradient(
    to left,
    #c0deff,
    #bad7e9,
    #00dbde,
    #2b3467
  ); */
  /* background: -o-linear-gradient(to left, #bad7e9,#11172B, #bad7e9,#11172B);
  background: -moz-linear-gradient(to left,#11172B, #bad7e9,#11172B, #bad7e9);
  background: linear-gradient(to left,#11172B, #bad7e9,#11172B, #bad7e9); */
  background-position: 50%;
  background-size: 300%;
  border-radius: 50%;
  /* border: 2px solid #0c1d35; */
  color: #0c1d35;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 3rem;
  justify-content: center;
  width: 3rem;
}
.botSubject,
.messages,
.showBotSubject .botIconContainer,
.showMessenger .botIconContainer {
  display: none;
}

.botIcon .Messages,
.botIcon .Messages_list {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.chat_close_icon {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 12px;
  z-index: 9;
}

.chat_on_icon {
  color: #fff;
  font-size: 25px;
  text-align: center;
}
.botIcon .Layout {
  -webkit-animation: appear 500ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -ms-animation: appear 500ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  animation: appear 500ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: rgb(63, 81, 181);
  bottom: 20px;
  border-radius: 10px;
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.1);
  box-sizing: content-box !important;
  color: rgb(255, 255, 255);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  max-height: 30px;
  max-width: 300px;
  min-width: 50px;
  opacity: 0;
  pointer-events: auto;
  position: fixed;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  -ms-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  z-index: 999999999;
}
.botIcon .Layout-open {
  border-radius: 10px;
  color: #fff;
  height: 500px;
  max-height: 500px;
  max-width: 300px;
  overflow: hidden;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -ms-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  width: 100%;
}
.botIcon .Layout-expand {
  height: 400px;
  max-height: 100vh;
  min-height: 300px;
  min-width: 25rem;
  position: relative;
}
.showBotSubject.botIcon .Layout-expand {
  display: block;
}
.botIcon .Layout-mobile {
  bottom: 10px;
}
.botIcon .Layout-mobile.Layout-open {
  min-width: calc(100% - 20px);
  width: calc(100% - 20px);
}
.botIcon .Layout-mobile.Layout-expand {
  border-radius: 0 !important;
  bottom: 0;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}
.botIcon .Messenger_messenger {
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.Messenger_header,
.Messenger_messenger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.Messenger_header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0c1d35;
  color: rgb(255, 255, 255);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 40px;
  padding-left: 10px;
  padding-right: 40px;
}

.Messenger_header h4 {
  -webkit-animation: slidein 0.15s 0.3s;
  -ms-animation: slidein 0.15s 0.3s;
  animation: slidein 0.15s 0.3s;
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  font-size: 16px;
  opacity: 0;
}
.botIcon .Messenger_prompt {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.botIcon .Messenger_content {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 80px;
}
.botIcon .Messages {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.botIcon .Input {
  background-color: #fff;
  border-top: 1px solid #e6ebea;
  color: #96aab4;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-bottom: 15px;
  padding-top: 17px;
  position: relative;
  width: 100%;
}
.botIcon .Input-blank .Input_field {
  max-height: 20px;
}
.botIcon .Input_field {
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 20px;
  padding-right: 45px;
  resize: none;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px !important;
}
.botIcon .Input_button-emoji {
  right: 45px;
}
.botIcon .Input_button {
  background-color: transparent;
  border: none;
  bottom: 15px;
  cursor: pointer;
  height: 25px;
  outline: none;
  padding: 0;
  position: absolute;
  width: 25px;
}
.botIcon .Input_button-send {
  right: 15px;
}
.botIcon .Input-emoji .Input_button-emoji .Icon,
.botIcon .Input_button:hover .Icon {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.botIcon .Input-emoji .Input_button-emoji .Icon path,
.botIcon .Input_button:hover .Icon path {
  fill: #2c2c46;
}

.message-user-icons {
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  /* background: #bad7e9; */
  /* background: linear-gradient(to left,#11172B, #bad7e9,#11172B, #bad7e9); */
  background-position: 50%;
  background-size: 200%;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.7em;
  justify-content: center;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  border-color: inherit;
}

.message-content {
  flex: 1 1 auto;
  color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0rem 1rem;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes appear {
  0% {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes slidein {
  0% {
    opacity: 0;
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (max-width: 412px) {
  .botIcon .Layout-open {
    width: 250px;
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* .message {
  color: black;
  border-radius: 20px 20px 20px 2px;
    width: 90%;  
  position: relative;
  padding: 0.5rem 1rem;
} */
/* .message:after {
  top: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #86bb71;
  border-width: 10px;
  margin-left: -10px;
  transform: rotate(180deg);
} */

.float-left {
  float: left;
}
.float-right {
  float: right;
}
.bot-message {
  color: black;
  border-radius: 10px 10px 10px 2px;
  position: relative;
  padding: 0.5rem 1.5rem;
  background: #94c2ed;
}
.user-message {
  color: black;
  border-radius: 10px 10px 2px 10px;
  position: relative;
  padding: 0.5rem 1.5rem;
  background:#eaf1fb /* #94c2ed; */
}
/* .bot-message:after {
  border-bottom-color: #94c2ed;
  right: 93%;
} */

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  color: #11172b;
  background-color: #11172b;
  color: #11172b;
  box-shadow: 9984px 0 0 0#11172B, 9999px 0 0 0#11172B, 10014px 0 0 0#11172B;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0#11172B, 9999px 0 0 0#11172B, 10014px 0 0 0#11172B;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0#11172B, 9999px 0 0 0#11172B,
      10014px 0 0 0#11172B;
  }
  33.333% {
    box-shadow: 9984px 0 0 0#11172B, 9999px 0 0 0#11172B, 10014px 0 0 0#11172B;
  }
  50% {
    box-shadow: 9984px 0 0 0#11172B, 9999px -10px 0 0#11172B,
      10014px 0 0 0#11172B;
  }
  66.667% {
    box-shadow: 9984px 0 0 0#11172B, 9999px 0 0 0#11172B, 10014px 0 0 0#11172B;
  }
  83.333% {
    box-shadow: 9984px 0 0 0#11172B, 9999px 0 0 0#11172B,
      10014px -10px 0 0#11172B;
  }
  100% {
    box-shadow: 9984px 0 0 0#11172B, 9999px 0 0 0#11172B, 10014px 0 0 0#11172B;
  }
}
