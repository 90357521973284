.signin {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #11172B;
  max-width: 100%;

  .login {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    // margin-top: 10%;
    // margin-bottom: auto;
  }

  .formfield {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
    margin: auto;
    width: 80% !important;
  }

  .form-control {
    border-radius: 0;
    border-width: 3px !important;
  }

  .tools {
    margin-top: 15px;
    font-size: small;
    margin-bottom: 0;
  }

  .loginBtn {
    margin: auto;
    align-items: center !important;
  }

  .card {
    width: 80% !important;
    height: 85% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    // margin-left: auto !important;
    // margin-right: auto !important;
    // border-radius: 10px !important;
    // height: 100% !important;
    // box-shadow: 5px 5px #bfbfbf !important;

  }

  .loginPage {
    flex-direction: column;
    gap: 5px;
    height: 500px;
  }

  input[type="checkbox"] {
    transform: scale(1.5);
  }

  .keepme {
    display: flex;
    gap: 10px;
    flex-direction: row;
    cursor: pointer;
  }

  .changeFont {
    font-size: 1rem !important;
    margin: auto !important;
  }

  .forpass {
    cursor: pointer;
  }

  .signinButton {
    font-size: 1rem;
    margin-top: 10px !important;
    background-color: #044482;
    color: white;
    border-radius: 0;
  }

  .pwd {
    position: relative;

    .icon-sty {
      width: 30px;
      color: #bfbfbf;
    }

    .password {
      cursor: pointer;

      position: absolute;
      top: 0;
      right: 10px;
    }
  }

  .signLink {
    margin-top: 10px;

    .signup {
      color: white;
    }
  }

  .spinlogin::before {
    font-size: medium;
  }

  .submit-btn {
    color: white !important;

    &:hover {
      color: white !important;
    }

    &:active {
      color: white !important;

      &:hover {
        color: white !important;
      }
    }
  }
}

.loginform {
  .signbutton {

    display: flex;
    justify-content: center;

    .css-dev-only-do-not-override-1w4v3hc {
      margin-inline-start: 0;
    }

  }
}

.forModal {
  align-items: center;

  .modal-footer {
    justify-content: flex-start !important;
  }

  .submit {
    text-align: center;
  }

  .forRequest {
    background-color: #044482;
  }
}

.modal-backdrop.show {
  opacity: 1 !important;
  position: fixed; // This makes it cover the entire scrollable page, not just browser height
  height: 100%;
  background-color: #11172B !important;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #11172B !important;
  background: #11172B !important;
}

@media screen and (max-width: 768px) and (min-width: 767px) {
  .signin .loginPage {
    gap: 0px;
  }

  .signin .card {
    width: 146% !important
  }
}

@media screen and (min-width:600px) and (max-width: 850px) {
  .signin .card {
    width: 100% !important;
    height: 70% !important
  }
}

@media screen and (min-width:1024px) and (max-width: 1500px)and (orientation : landscape) {
  .signin .card {
    width: 70% !important;
    height: 85% !important
  }

  .signin .loginPage {
    gap: 0px !important;
  }
}

@media screen and (max-height: 650px) {
  .signin .loginPage .pic img{
    height: 190px !important;
  }
  .signin .card {
    width: 70% !important;
    height: 96% !important;
}
}

@media screen and (max-height: 450px) {
  .signin .loginPage .pic img{
    height: 150px !important;
  }
}


@media screen and (max-height: 390px) {
  .signin .loginPage .pic img{
    height: 50px !important;
  }
}

@media screen and (min-width:1200px) and (max-width: 2000px) {
  .signin .card {
    width: 50% !important;
    // height: 00% !important
  }

  .signin .loginPage {
    gap: 0px !important;
  }
}