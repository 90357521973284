$color-primary:#11172B;
$color-secondary: #f0f2f5;

.bg-sidebar {
  background-color: $color-secondary;
}

li {
  list-style: none;
}

.sidebar {
  position: fixed;
  width: 4.5rem;
  min-height: 41rem;
  top: 4.4rem;
  z-index: 50;
}

.nav-option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem;
  text-align: center;
}

.p-4 {
  padding: 1rem;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}

.nav-svg {
  color: gray;
  margin-left: 5px;

  &:hover {
    border-radius: 0.1rem;
    color: $color-primary;
    transition: 500ms ease-in-out;
    &:nth-child(1) {
      box-shadow: 10px;
    }
  }
}

.submenu-hover-nav {
  position: fixed;
  top: 4.6rem;
  left: 4.6rem;
  bottom: 10px;
  z-index: 50 !important;
  padding: 1rem;
  min-height: 30rem;
  background: #f0f2f5;
  box-shadow: 10px 10px 5px lightblue;
  border-radius: 0.4rem;
  width: 12rem;
  overflow-y: scroll;
}

.show-me:hover + .d-none {
  display: block !important;
}

.show-me:hover .sidebar:hover .d-none {
  display: block !important;
}

.d-none:hover {
  display: block !important;
}

.border-b-1 {
  border-bottom: 1px solid;
}

.h-100 {
  height: 100vh;
}

@media (max-width: 767px) {
  .nav-option {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.7rem;
    text-align: center;
}
}
