.sync-bar {
    position: fixed; 
    width: 100%;
    z-index: 50;
  }
  .bar--progress {
    position: absolute;
    width: 0%;
    height: 1rem;
    background: green;
    transition: 0.25s linear;
  }
  
  #sync-text {
    text-align: center;
    position: relative;
    top: -1.5rem;
    color: white;
    font-weight: 600;
  }
  