.crossCheck {
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     border-color: green !important;
  //     background: green !important;
  //   }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: green !important;
    background: green !important;
  }
}
