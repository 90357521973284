.waveform {
  display: grid;
  place-items: center;
}
.waveform .sound-wave {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.waveform .bar {
  animation-name: wave-lg;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  background: #11172b;
  margin: 0 1.5px;
  width: 1px;
  height: 10px;
  // height: 20rem;
}
.waveform .static-bar {
  animation-name: wave-lg;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  background: #11172b;
  margin: 0 1.5px;
  width: 1px;
  height: 10px;
  // height: 20rem;
}
.waveform .bar:nth-child(-n + 7),
.waveform .bar:nth-last-child(-n + 7) {
  animation-name: wave-md;
}
.waveform .bar:nth-child(-n + 3),
.waveform .bar:nth-last-child(-n + 3) {
  animation-name: wave-sm;
}

@keyframes wave-sm {
  0% {
    opacity: 0.35;
    height: 10px;
  }
  100% {
    opacity: 1;
    height: 25px;
  }
}
@keyframes wave-md {
  0% {
    opacity: 0.35;
    height: 15px;
  }
  100% {
    opacity: 1;
    height: 50px;
  }
}
@keyframes wave-lg {
  0% {
    opacity: 0.35;
    height: 15px;
  }
  100% {
    opacity: 1;
    height: 70px;
  }
}

.waveform .bar:nth-child(3n + 0) {
  height: 20px;
  width: 1px;
}
.waveform .bar:nth-child(2n + 0) {
  height: 10px;
  width: 1px;
}

// .waveform {
//   background: #1b1c1c;
//   height: 100vh;
//   display: grid;
//   place-items: center;

//   .sound-wave {
//     height: 50px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   .bar {
//     animation-name: wave-lg;
//     animation-iteration-count: infinite;
//     animation-timing-function: ease-in-out;
//     animation-direction: alternate;
//     background: #f32968;
//     margin: 0 1.5px;
//     height: 10px;
//     width: 1px;
//   }
// }

#preloader_1 {
  position: relative;
  display: flex;
  margin-top: 20%;
}
#preloader_1 div {
  display: flex;
  width: 10px;
  background: #9b59b6;
  animation: preloader_1 2s infinite ease-in-out;
}

#preloader_1 div:nth-child(2) {
  left: 1px;
  animation-delay: 0.2s;
}
#preloader_1 div:nth-child(3) {
  left: 1px;
  animation-delay: 0.4s;
}
#preloader_1 div:nth-child(4) {
  left: 1px;
  animation-delay: 0.6s;
}
#preloader_1 div:nth-child(5) {
  left: 1px;
  animation-delay: 0.8s;
}
@keyframes preloader_1 {
  // 0% {
  //   height: 15px;
  //   transform: scaleY(0);
  //   background: #9c254d;
  // }
  // 15% {
  //   height: 15px;
  //   transform: scaleY(1);
  //   background: #9c254d;
  // }
  // 25% {
  //   height: 15px;
  //   transform: scaleY(1.5);
  //   background: #9c254d;
  // }
  35% {
    height: 15px;
    transform: scaleY(2);
    background: #9c254d;
  }
  45% {
    height: 15px;
    transform: scaleY(1.5);
    background: #9c254d;
  }
  50% {
    height: 15px;
    transform: scaleY(1);
    background: #9c254d;
  }
  70% {
    height: 15px;
    transform: scaleY(0);
    background: #9c254d;
  }
  // 85% {
  //   height: 15px;
  //   transform: scaleY(0.3);
  //   background: #9c254d;
  // }
  // 100% {
  //   height: 15px;
  //   transform: scaleY(0.2);
  //   background: #9c254d;
  // }
}
