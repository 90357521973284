.technician{
    tr{
        text-align: center  !important;
        width: 50% !important;
        border-color: #0c1d35 !important;
      }
      .shadow-lg {
        box-shadow: 0 10px 15px -3px rgb(0, 0, 0 / 0.1), 0 4px 6px -4px rgb(0, 0, 0 / 0.1);
      }
      
}
.technicianSpinner{
  height: 25vh !important;
}