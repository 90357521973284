.signupPage {
  background-color:#11172B;
  width: 100%;
  height: 100vh;
  justify-content: center;
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
  }
  .header {
    margin-bottom: 20px;
  }
  .pageName {
    color: white;
    font-weight: 700;
    font-size: 18px;
  }
  .signupForm {
    height: 500px;
  }
  .card {
    // width: 100% !important;
    // display: flex !important;
    // align-items: center !important;
    // justify-content: center !important;
    // // margin-left: auto !important;
    // // margin-right: auto !important;
    // background-color:#11172B;
    border-radius: 2% !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: white;
  }
  .formFields {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
  }
  .inputField {
    border-radius: 0 !important;
  }
  .submitBtn {
    margin: 10px 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .sendBtn {
    text-align: center;
    background-color: white;
    color: darkgray;
    width: 85%;
    border-radius: 40px;
  }

  .linkToLogin {
    margin: 0;
    padding: 0.5rem 0.1rem;
  }

  .ant-input-number {
    width: 100% !important;
  }
}

.forPasswordModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.forgotPassword-container {
  border-radius: 5px;
  background-color: white;
  width: 80%;
  min-height: 50vh;
}

.reset-password-text {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  font-size: 2rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.mt-6-5rem {
  margin-top: 6.5rem;
}


