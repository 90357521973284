@import "../../../services/css-variable.scss";

.ant-layout-header .ant-menu {
  background: #f5f5f5 !important;
  color: white !important;
}



.ant-menu-horizontal {
  border-bottom: 0px !important;
}

.nav-icon{
  display: none;
}

.mobile-navbar{
  display: none;
}

.desk-navbar{
  display: block;
}

@media screen and (max-width: 767px) and (min-width: 250px){
  .nav-icon{
   display: block;
  }
  .mobile-navbar{
   display: block;
 }
 .desk-navbar{
   display: none;
 }
 }