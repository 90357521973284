body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flex{
  display: flex !important;
}



html::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.ant-btn-foucs {
  color: #11172b !important;
  border-color: black !important;
  background: #fff;
}
.ant-btn:hover {
  color: #11172b !important;
  border-color: #11172b !important;
  background: #fff;
}
.ant-btn:focus {
  color: black !important;
  border-color: gray !important;
  background: #fff;
}

.ant-btn:active {
  color: #11172b;
  border-color: #11172b !important;
  background: #fff;
}

.hover-color-red:hover {
  color: red !important;
  border-color: red !important;
}

.hover-color-black:hover {
  color: #11172b !important;
}

.select-none {
  user-select: none;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.pt-1rem {
  padding-top: 1rem;
}

.ant-layout-footer {
  padding: 0.5rem 1rem !important;
}

/* .overflow-y {
  overflow-y: auto;
} 
.overflow-y::-webkit-scrollbar {
  display: none;
}
 
.overflow-y {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}   */

.m-left-8 {
  margin-left: 8rem;
}

.m-right-15 {
  margin-right: 15rem;
}

.h-screen {
  height: 78vh !important;
}

.justify-evenly {
  justify-content: space-evenly;
}

.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}

.pointers-compuslory {
  pointer-events: all !important;
}

.ant-pagination-item-active {
  border-color: #11172b !important;
}

.ant-input:focus {
  border-color: #11172b !important;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}
.ant-input:hover {
  border-color: #11172b !important;
}

.ant-comment-content-author {
  margin-bottom: 0 !important;
}

.ant-pagination-item-active {
  border-color: #11172b !important;
}

.ant-pagination-item:hover {
  border-color: #11172b !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #11172b !important;
  color: #11172b !important;
}

.ant-comment-inner {
  padding: 8px 0 !important;
}

.text-lg {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.5rem;
}
border-b-4 {
  border-bottom: 1px solid#11172B;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.w-full {
  width: 100%;
}

video {
  border-width: 4px;
  border-radius: 1rem;
  border: 1px solid#11172B;
}

.motion span {
  position: relative;
  bottom: -1em;
  opacity: 0;
  animation: move-text 1s forwards infinite;
}
.downloadbtn {
  background-color: #0a1a44;
  padding:10px;
  border-radius: 26px;
  border: 1px solid #0a1a44;
  color:white;
  width:50%;
  font-size: 20px;
  /* margin-right:20px */
}
.sysimgcontainer {
  width: 850px;
  height: 480px;
}
.disflex {
  display: flex;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* align-items:center */
  /* min-height: 100vh; */
}

.content {
  flex: 1;
}

.footer {
  /* Styles for the footer */
   display:flex;
  justify-content: space-around;
  align-items: center;
  /* position: absolute;
  bottom: 0;
  justify-content: space-between; */
  margin-bottom: 18px;
}
.footer-wrapper {
  position: relative;
}
.mar-60 {
  margin-right: 60px;
}
.signinlogo {
  /* width:'70px',height:'60px',marginTop:'10px' */
  width:70px;
  height: 60px;
  margin-top: 10px;
}
.middlecontainer {
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5rem;
}
.applogocontainer {
  /* width:'600px',height:'400px' */
  width: 520px;
  height: 320px;
  /* margin-top: -180px; */
}
.syslogo {
  width:100%
}
.mal-2 {
  margin-left: 16px;
}
.mal-4 {
  margin-left:24px;

}
.mobdis {
  display:block
}
.copyrighttext {
  display: none;
}
.iconsize {
  font-size: 52px;
}
.footimgsize {
  width:52px;
  height:52px;
}
/* .item {
  position: absolute;
} */
@keyframes move-text {
  0% {
    bottom: -0em;
    opacity: 1;
  }

  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  body {
    /* transform: scale(0.90); */
  }
}

.select-none {
  user-select: none;
}

.ant-list-empty-text {
  padding: 0px !important;
}

.spin-icon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.remove-btn-css {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.link-text-bg-black:focus {
  background-color: #11172b !important;
}
.link-text-bg-black:active {
  background-color: #11172b;
}

.ml-2 {
  margin-left: 0.5rem;
}

.span-heading {
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 0.1rem;
}

.audio-bar {
  width: 1px;
  height: 1px;
  background: #11172b;
  transition: width 0.1s linear;
}

.rhema_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  color: #0c1d35;
}

.text-theme {
  color: #0c1d35;
}

#rhema-mic,
#book_left,
#book_right,
#book_outline {
  animation: micAnimate 4s infinite ease-in-out;
}

@keyframes micAnimate {
  0% {
    opacity: 0;
    stroke-dashoffset: 50;
  }

  100% {
    opacity: 1;
    stroke-dashoffset: -400;
  }
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.go-back-dropdown {
  position: fixed;
  z-index: 50 !important;
  background: #f0f2f5;
  box-shadow: 10px 10px 5px lightblue;
  width: 12rem;
  border-radius: 5%;
  top: 8rem;
}
.playlist-dropdown {
  position: fixed;
  z-index: 50 !important;
  background: #f0f2f5;
  box-shadow: 10px 10px 5px lightblue;
  width: 12rem;
  border-radius: 5%;
  top: 15.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.hover-bg-gray:hover {
  background-color: gainsboro;
  transition: 500ms ease-out;
}

.break-spaces {
  white-space: break-spaces;
}

.flex-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.lowercase {
  text-transform: lowercase;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid#11172B !important;
  z-index: 2;
}

.mxwidth-800 {
  max-width: 800px;
}
.mxwidth-400 {
  max-width: 400px;
}

.h-full {
  height: 100%;
}
.spinner {
  width: 25px;
  height: 25px;
  border: 5px dotted black;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.shrink-2 {
  flex-shrink: 2;
}

.stick-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.dots-circle-spinner {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  line-height: 1;
  vertical-align: middle;
  border-radius: 1rem;
  transition: all 150ms linear 0s;
  transform: scale(0);
  opacity: 0;
  box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em,
    0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em,
    -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em,
    0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
}
.dots-circle-spinner.loading {
  transform: scale(0.5);
  opacity: 1;
  -webkit-animation: 1.5s linear 150ms normal infinite forwards running
    dots-circle-rotation;
  animation: 1.5s linear 150ms normal infinite forwards running
    dots-circle-rotation;
}

@-webkit-keyframes dots-circle-rotation {
  100% {
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em,
      0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em,
      -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em,
      0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
  }
  87.5% {
    box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em,
      0em 2em 0 -0.3125em, -1.41421356em 1.41421356em 0 -0.25em,
      -2em 0em 0 -0.1875em, -1.41421356em -1.41421356em 0 -0.125em,
      0em -2em 0 -0.0625em, 1.41421356em -1.41421356em 0 0em;
  }
  75% {
    box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em,
      0em 2em 0 -0.25em, -1.41421356em 1.41421356em 0 -0.1875em,
      -2em 0em 0 -0.125em, -1.41421356em -1.41421356em 0 -0.0625em,
      0em -2em 0 0em, 1.41421356em -1.41421356em 0 -0.4375em;
  }
  62.5% {
    box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em,
      0em 2em 0 -0.1875em, -1.41421356em 1.41421356em 0 -0.125em,
      -2em 0em 0 -0.0625em, -1.41421356em -1.41421356em 0 0em,
      0em -2em 0 -0.4375em, 1.41421356em -1.41421356em 0 -0.375em;
  }
  50% {
    box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em,
      0em 2em 0 -0.125em, -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em,
      -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em,
      1.41421356em -1.41421356em 0 -0.3125em;
  }
  37.5% {
    box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em,
      0em 2em 0 -0.0625em, -1.41421356em 1.41421356em 0 0em,
      -2em 0em 0 -0.4375em, -1.41421356em -1.41421356em 0 -0.375em,
      0em -2em 0 -0.3125em, 1.41421356em -1.41421356em 0 -0.25em;
  }
  25% {
    box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em,
      0em 2em 0 0em, -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em,
      -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em,
      1.41421356em -1.41421356em 0 -0.1875em;
  }
  12.5% {
    box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em,
      0em 2em 0 -0.4375em, -1.41421356em 1.41421356em 0 -0.375em,
      -2em 0em 0 -0.3125em, -1.41421356em -1.41421356em 0 -0.25em,
      0em -2em 0 -0.1875em, 1.41421356em -1.41421356em 0 -0.125em;
  }
  0% {
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em,
      0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em,
      -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em,
      0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
  }
}
@keyframes dots-circle-rotation {
  100% {
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em,
      0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em,
      -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em,
      0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
  }
  87.5% {
    box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em,
      0em 2em 0 -0.3125em, -1.41421356em 1.41421356em 0 -0.25em,
      -2em 0em 0 -0.1875em, -1.41421356em -1.41421356em 0 -0.125em,
      0em -2em 0 -0.0625em, 1.41421356em -1.41421356em 0 0em;
  }
  75% {
    box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em,
      0em 2em 0 -0.25em, -1.41421356em 1.41421356em 0 -0.1875em,
      -2em 0em 0 -0.125em, -1.41421356em -1.41421356em 0 -0.0625em,
      0em -2em 0 0em, 1.41421356em -1.41421356em 0 -0.4375em;
  }
  62.5% {
    box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em,
      0em 2em 0 -0.1875em, -1.41421356em 1.41421356em 0 -0.125em,
      -2em 0em 0 -0.0625em, -1.41421356em -1.41421356em 0 0em,
      0em -2em 0 -0.4375em, 1.41421356em -1.41421356em 0 -0.375em;
  }
  50% {
    box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em,
      0em 2em 0 -0.125em, -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em,
      -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em,
      1.41421356em -1.41421356em 0 -0.3125em;
  }
  37.5% {
    box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em,
      0em 2em 0 -0.0625em, -1.41421356em 1.41421356em 0 0em,
      -2em 0em 0 -0.4375em, -1.41421356em -1.41421356em 0 -0.375em,
      0em -2em 0 -0.3125em, 1.41421356em -1.41421356em 0 -0.25em;
  }
  25% {
    box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em,
      0em 2em 0 0em, -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em,
      -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em,
      1.41421356em -1.41421356em 0 -0.1875em;
  }
  12.5% {
    box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em,
      0em 2em 0 -0.4375em, -1.41421356em 1.41421356em 0 -0.375em,
      -2em 0em 0 -0.3125em, -1.41421356em -1.41421356em 0 -0.25em,
      0em -2em 0 -0.1875em, 1.41421356em -1.41421356em 0 -0.125em;
  }
  0% {
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em,
      0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em,
      -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em,
      0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
  }
}

.h-fit {
  height: fit-content;
}

/* .split-screen-scroll {
  max-height: 36rem;
  overflow: hidden;
  overflow-y: scroll;
} */
.split-screen-scroll {
  max-height: 36rem;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 50;
  scroll-behavior: smooth;
  height: auto;
}

.bg-chatbot {
  /* background: #94c2ed !important; */
  /* border-color: #94c2ed !important; */
  color: black !important;
  padding: 1px 0 !important;
}
.bg-chatbot:hover {
  background: inherit !important;
  border-color: #94c2ed !important;
}

.corner-close {
  position: relative;
  left: 1rem;
}

.speech-reco {
  background-color: #f0f2f5;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.items-base {
  align-items: baseline;
}

#audio-source {
  order: 1;
}

#video-source {
  order: 1;
}
#script-source {
  order: 2;
}

.bottom-end {
  position: absolute;
  bottom: 0;
}

.ant-menu-light .ant-menu-submenu-title:hover {
  color: #bcc8cc !important;
  background: transparent !important;
}

.ant-header-items-css,
.ant-header-items-css:focus,
.ant-header-items-css:active,
.ant-header-items-css:focus-visible {
  color: black !important;
  background: transparent !important;
}

.ant-header-outer-items-css,
.ant-header-outer-items-css:hover,
.ant-header-outer-items-css:focus,
.ant-header-outer-items-css:active {
  color: #bcc8cc !important;
  background: transparent !important;
}

.ant-header-items-css:hover {
  /* background: #bcc8cc !important; */
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #bcc8cc !important;
}

.p-wavefrom {
  border: 2px solid gray;
  max-height: 2rem;
  border-radius: 25%;
  width: 0px;
  height: 1rem;
  margin: 0px 1px;
  background: gray;
}

.p-wavefrom:first-child {
  border: 2px solid black;
  max-height: 2rem;
  border-radius: 25%;
  width: 0px;
  height: 1rem;
  margin: 0px 1px;
  background: black;
}
.p-wavefrom:nth-child(2) {
  border: 2px solid black;
  max-height: 2rem;
  border-radius: 25%;
  width: 0px;
  height: 1rem;
  margin: 0px 1px;
  background: black;
}

.blink {
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 600px) {
  .middlecontainer {
    display: block !important;
    align-items: center;
  justify-content: space-between;
  margin-top: 2.5rem;
  }
  .applogocontainer {
    width: 100% !important;
    height: 240px !important;
    margin-top: -60px !important;
}
.downloadbtn {
  width: 100% !important;
}
.mar-60 {
  margin-right:0px !important;
}
.syslogo {
  margin-top: 20%;
  width: 100%;
  /* width: auto !important; */
}
.footer {
  /* Styles for the footer */
   /* display:block !important; */
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  /* margin-top: 54px; */
}
.disflex {
  display: block !important;
} 
.copyrighttext {
  margin-top: 40px;
  margin-bottom: 20px;
}
.mal-2 {
  margin-left: 36px;
}
.mal-4 {
  margin-left:0px !important;
  margin-top: 16px;
}
.mobdis {
  display: none !important;
}
.mobcenter {
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.item {
  margin-top: 20px;
}
.mobcopyrighttext {
  display:none
}
.copyrighttext {
  display: block !important;
  text-align: center;
}
.iconsize {
  font-size: 32px !important;
}
.footimgsize {
  width:32px !important;
  height:32px !important;
}
.sysimgcontainer {
  width: 100% !important;
  height: 240px;
  
}
}
@media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2) {

  html {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    font-family: "WhitneyLight";
  }
}
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {

  html {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    font-family: "WhitneyLight";
  }
}
@media only screen and (device-width : 390px) and (device-height : 844px) and (-webkit-device-pixel-ratio : 3) {

  html {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    font-family: "WhitneyLight";
  }}
  @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {

    html {
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
      font-family: "WhitneyLight";
    }
  }

@media only screen and (min-width:600px) and (max-width: 820px) {
  .sysimgcontainer {
   width: 100%;
   margin-top: 50px;
  }
  /* .middlecontainer {
    display: block;
  } */
  .applogocontainer {
    width: 100%;
    height: 320px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .mobtabbtn { 
    margin-top: -81px;
  }
  .item {
    margin-top: 20px;
  }
  .mobcopyrighttext {
    display:none
  }
  .copyrighttext {
    display: block !important;
    text-align: center;
  }
  .iconsize {
    font-size: 32px !important;
  }
  .footimgsize {
    width:32px !important;
    height:32px !important;
  }
  .mobdis {
    display: none !important;
  }
}
@media only screen and (min-width:820px) and (max-width: 1024px) {
  .sysimgcontainer {
   width: 100%;
   margin-top: 50px;
  }
  /* .middlecontainer {
    display: block;
  } */
  .applogocontainer {
    width: 100%;
    height: 320px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .mobtabbtn { 
    margin-top: -81px;
  }
  .item {
    margin-top: 20px;
  }
  .mobcopyrighttext {
    display:none
  }
  .copyrighttext {
    display: block !important;
    text-align: center;
  }
  .iconsize {
    font-size: 32px !important;
  }
  .footimgsize {
    width:32px !important;
    height:32px !important;
  }
  .mobdis {
    display: none !important;
  }
}
.z-1000{
  z-index:1000
}

.justify-between {
  justify-content: space-between;
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

.sec-marginLeft-1:nth-child(2) {
  margin: 0 1rem;
}

.sharedFilesPage{
  min-height: 83dvh;
  padding: 0 2rem;
}

.ant-float-btn-body{
  background-color: #11172b !important;

}