@import "../../services/css-variable.scss";

.comments {


  
  .submit-btn {
    color: white;
    background-color: $color-primary;
    border-radius: 0;
  }

  .w-86 {
    width: 86% !important;
  }

  .form {
    border: 1px solid;
    border-radius: 5px;
  }

  .border-r-1 {
    border-color: $color-primary;
    border-right: 1px solid;
  }

  .border-none {
    border: none;
    box-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }

  .white-hover:hover {
    color: white !important;
  }

  .btn-cancel {
    background: #ef4444;
  }
}
