
.myProgress{
  // height: 83vh;
  height: 100%;
  display: flex;
  justify-content: center;
  // background-color:#11172B;
  max-width: 100%;
  // max-height: 100%;
  .headline{
      text-align: center;
  }
  .myProgressCard{
      height: auto !important;
  }
  .btnSty{
      background-color:#11172B !important;
      color: #fff !important;
      .ant-btn{
        background-color:#11172B !important;
        border:#11172B !important;
        color: #fff !important;
      }
  }

  .back{
    display: flex;
    flex-direction: row;
    // gap: 25%;
    justify-content: space-between;
  }
  .titleText{
    padding-top: 2%;
  }

  .chunkCard{
    border: none;
    // justify-content: center;
  }
 .ant-progress-inner{
  width: 98% !important;
 }
.progress{
  background-color: #fff !important;
}

.pgr-dimensions {
  height: 250px;
  width: 250px;
}

.oyscrl {
  overflow-y: scroll;
}

.fsize {
  font-size: 14px;
}
@media (max-width: 767px) {
  .pgr-dimensions {
    height: 80px;
    width: 80px;
  }
  .fsize {
    font-size: 12px;
  }
  .mpr-resetbtn .w-25 {
    width:100% !important
  }
  .fsizesm {
    font-size: 10px;
  }

  .oyscrl {
    overflow-y: visible;
  }
}

}

