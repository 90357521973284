.waveform-container {
    width: 100%;
    max-width: 600px; /* Adjust the maximum width as needed */
    margin: 0 auto;
    margin-top: 20px; /* Adjust the top margin as needed */
    position: relative;
    overflow: hidden;
  }
  
  .waveform {
    width: 100%;
    height: 50px; /* Adjust the height of the waveform */
    display: flex;
    justify-content: space-between;
  }
  
  .bar {
    width: 10px; /* Adjust the width of each bar */
    background: #9b59b6;
    animation: preloader_1 2s infinite ease-in-out;
    margin: 0; /* Remove margin between bars */
    padding: 0; /* Remove padding between bars */
  }

  @media screen and (min-width:600px)  and (max-width: 850px){
    .waveform {
      width: 60% !important;
      
    }
   }
  
  @keyframes preloader_1 {
    0%, 100% {
      transform: scaleY(0.2);
    }
    50% {
      transform: scaleY(1.2);
    }
  }
  