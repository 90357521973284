@import "../../../services/css-variable.scss";

.draftDetails {
  background-color:#11172B;
  padding: 1rem;
  .cardTitle {
    color: #fff;
    .back {
      display: flex;
      flex-direction: row;
    }
    .titleText {
      // margin-top: 13px;

      margin: 0 auto;
    }
  }
  .selectLanguage {
    display: flex;
    flex-direction: column;
  }
  .ant-tabs-tabpane {
    height: 14rem;

    .audioHeading {
      background-color:#11172B !important;
      text-align: center;
      display: flex;
      flex-direction: row;
      gap: 30%;
      margin-left: auto;
      margin-right: auto;
      .iconPlayi {
        color: #fff !important;
        font-size: 14px;
        margin-left: 10px;
        font-size: 25px;
      }
      .iconPlay {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
    .headingText {
      text-align: center;
      color: #fff !important;
    }
  }
  .ant-tabs-tabpane {
    .scriptHeading {
      background-color:#11172B !important;
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
    }
    .scriptText {
      text-align: center;
      color: #fff !important;
    }
  }
  .ant-tabs-tabpane {
    .recording {
      // border: 1px solid#11172B;
      height: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      .recordingIcon {
        justify-content: flex-end;
      }
    }
    .draftaudioHeading {
      // background-color:#11172B !important;
      display: flex;
      flex-direction: row;
      gap: 110px;
    }
    .iconPlayi {
      color:#11172B;
      font-size: 22px;
    }
    .iconPlay {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .actionIcon {
      display: flex;
      flex-direction: row;
      justify-content: flex-end !important;
    }
    .texthead {
      display: flex;
      flex-direction: row;
      text-align: center;
      .headingText {
        color:#11172B !important;
      }
    }

    .draftIcons {
      // color: #fff;
      margin-right: 10px;
      margin-left: 10px;
      font-size: 18px;
      // margin-top: 4px
    }
    .draftFile {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .scriptToDraft {
      height: 7rem;
    }

    .draftTextArea {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    .saveBtn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  .data {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .sourceLanguage {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .scriptFiles {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .audioFiles {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.audioFileSource {
  width: 100%;
}

.audioFileSource audio {
  width: 100%;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.mx-4 {
  margin: 0 1rem;
}
.mx-2 {
  margin: 0 0.5rem;
}
.my-2 {
  margin: 0.5rem 0;
}
.mr-2 {
  margin-right: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}
.py-2 {
  padding: 1rem 0;
}

.text-center {
  text-align: center;
}

.select-none {
  user-select: none;
}

.justify-center {
  justify-content: center;
}

// .draftCardBody {
//   overflow-y: auto;
// }

.pb-2 {
  padding-bottom: 0.5rem;
}

.w-50 {
  width: 50%;
}

.source-language-container div:nth-of-type(2) {
  flex-grow: 3;
}

.draft-details {
  //background-color: $color-primary; //NEED TO CHECK
  width: 100%;
  margin-left: -4px;
  padding: 1rem;

  .draft-details-wrapper {
    width: 100%;
    padding: 0rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    min-height: 80vh;
  }

  .back-arrow {
    display: flex;
    align-items: center;
  }

  .chunkname {
    padding-top: 0.8rem;
  }
}

.hover {
  &:hover {
    color: $color-primary;
  }
}

.fsize {
  font-size: 20px;
}
@media (max-width: 767px) {
  .fsize {
    font-size: 14px;
  }
}